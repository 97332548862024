<template>
  <div>
    <v-card :loading="loading">
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
            md="12"
            lg="6"
          >
            <v-card-title class="px-2">
              <router-link
                class="font-weight-medium text-decoration-none me-2  py-0"
                :to="{ name: 'Transfer-in'}"
              >
                <v-tooltip
                  color="#212121"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      class="py-0"
                      icon
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("back") }}</span>
                </v-tooltip>
              </router-link> {{ $t("list_transfer_in") }} {{ transferData.transfer_id }}
              <v-spacer></v-spacer>
              {{ $t("from") }} : {{ transferData.shop_id_name }} ({{
                transferData.shop_id
              }})
            </v-card-title>

            <v-data-table
              :headers="[
                {
                  text: '#',
                  align: 'start',
                  sortable: false,
                  value: 'drug_id_pri',
                },
                {
                  text: $t('drug_code'),
                  value: 'transferorder_drug_id',
                },
                {
                  text: $t('drug_name'),
                  value: 'transferorder_drug_name',
                },
                {
                  text: $t('cost_price'),
                  value: 'transferorder_cost',
                },
                {
                  text: $t('amount'),
                  value: 'transferorder_amount',
                },
                {
                  text: $t('unit'),
                  value: 'transferorder_drug_unit',
                },
              ]"
              :items="drugTransferInList"
              :mobile-breakpoint="0"
              :items-per-page="100"
              :disable-sort="true"
              hide-default-footer
              :no-data-text="`${loading? $t('data_loading_please_wait'): $t('no_information')} !`"
            >
              <template v-slot:[`item.drug_id_pri`]="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:[`item.shop_id_name_to`]="{ item }">
                {{ item.shop_id_name_to }} ({{ item.shop_id_to }})
              </template>
              <template v-slot:[`item.transfer_status_id`]="{ item }">
                <span
                  v-if="item.transfer_status_id == '1'"
                  class="badge bg-warning"
                >
                  <i class="fa fa-check align-middle me-1"></i>
                  {{ $t("waiting_check") }}
                </span>
                <span
                  v-if="item.transfer_status_id == '2'"
                  class="badge bg-success"
                >
                  <i class="fa fa-check align-middle me-1"></i>
                  {{ $t("successful_transfer") }}
                </span>
                <span
                  v-if="item.transfer_status_id == '3'"
                  class="badge bg-danger"
                >
                  <i class="fa fa-times align-middle me-1"></i>
                  {{ $t("cancel") }}
                </span>
              </template>
              <template v-slot:[`item.user_fullname_to`]="{ item }">
                <span v-if="item.transfer_status_id == 1">
                  {{ $t("waiting_check") }}
                </span>
                <span v-else>
                  {{ item.user_fullname_to }}
                </span>
              </template>
              <template v-slot:[`item.option`]="{}">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="set-font-kanit">{{ $t("detail") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
            lg="6"
          >
            <v-card-title class="px-2">
              <span class="py-2">     {{ $t("list_drug_equipment") }}</span> <v-spacer></v-spacer>{{ $t("user_is_list") }} : {{ profileData.user_fullname }}
            </v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      #
                    </th>
                    <th class="text-left">
                      {{ $t("drug_code") }}
                    </th>
                    <th class="text-left">
                      {{ $t("drug_name") }}
                    </th>
                    <th class="text-right">
                      {{ $t("sale_price") }}
                    </th>
                    <th class="text-right">
                      {{ $t("amount") }}{{ $t("balance") }}
                    </th>
                    <th class="text-left">
                      {{ $t("unit") }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="statusMapData">
                  <tr
                    v-for="(item, index) in fillerDrugInShopList"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.drug_id }}</td>
                    <td>
                      <v-autocomplete
                        v-model="fillerDrugInShopList[index]"
                        outlined
                        dense
                        item-text="drug_name"
                        item-value="drug_id"
                        hide-details
                        return-object
                        :items="drugInShopList"
                        @change="checkNull()"
                      ></v-autocomplete>
                    </td>
                    <td class="text-right">
                      {{ item.drug_price }}
                    </td>
                    <td class="text-right">
                      {{ item.drug_total }}
                    </td>
                    <td>{{ item.drug_unit }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
            <v-textarea
              v-model="transfer_comment"
              outlined
              :label="$t('note')"
              rows="4"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!statusCheck || loadingProcess"
                :loading="loadingProcess"
                color="primary"
                @click="statusClick =1;isUserComfirm =true"
              >
                {{ $t("save") }}
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="statusClick =2;isUserComfirm =true"
              >
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <UserConfirm
      v-model="isUserComfirm"
      @onSend="checkStatusComfirm"
    />
  </div>
</template>
<script>

// router.currentRoute.params.id
import { ref, onMounted } from '@vue/composition-api'
import { mdiArrowLeftBold } from '@mdi/js'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'
import router from '@/router'
import store from '@/store'
import profile from '@/api/profile/profile'
import transferIn from '@/api/drugTransfer/transferIn'
import drugStore from '@/api/DrugManagement/drugStore'
import { i18n } from '@/plugins/i18n'
import { required } from '@/@core/utils/validation'

export default {
  components: {
    UserConfirm,
  },

  setup() {
    const comment = ref('')
    const dialogCancel = ref(false)
    const statusUpdateNavbar = ref(false)
    const isUserComfirm = ref(false)
    const transfer_id = ref('')

    // true data
    const drugTransferInList = ref([])
    const fillerDrugInShopList = ref([])
    const drugInShopList = ref([])
    const statusMapData = ref(false)
    const transfer_comment = ref('')
    const transferData = ref('')
    const profileData = ref('')
    const userData = ref('')
    const dataUserRole = ref([])
    const statusOpenConfirmCreate = ref(false)
    const statusCheck = ref(false)
    const loading = ref(true)
    const statusClick = ref(0)
    const loadingProcess = ref(false)

    const { getProfile: profileGet } = profile
    const { transferInGet, transferInStatusUpdate, transferInDrugGet } = transferIn
    const { drugList } = drugStore
    onMounted(() => {
      transfer_id.value = router.currentRoute.params.id
      getDrugTranferIn()
      getTransferIn()
      getProfile()
    })

    const checkNull = () => {
      let check = true
      for (const item of fillerDrugInShopList.value) {
        if (!item.drug_id) {
          check = false
          break
        }
      }
      statusCheck.value = check
      loading.value = false
    }
    const getProfile = () => {
      profileGet().then(res => {
        profileData.value = res
      })
    }
    const getTransferIn = () => {
      transferInGet(transfer_id.value).then(res => {
        transferData.value = res.data
      })
    }
    const comfirmTransfers = () => {
      loadingProcess.value = true
      const drug_id_pri = []
      const drug_id_pri_to = []
      drugTransferInList.value.forEach(element => {
        drug_id_pri.push(element.drug_id_pri)
      })
      fillerDrugInShopList.value.forEach(element => {
        drug_id_pri_to.push(element.drug_id_pri)
      })
      const body = {
        transfer_id_pri: transfer_id.value,
        transfer_status_id: '2',
        transfer_comment:
          `${transfer_comment.value
          } (${i18n.t('more_comment')}: ${
            profileData.value.user_fullname
          })`,
        drug_id_pri,
        drug_id_pri_to,
      }
      transferInStatusUpdate(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loadingProcess.value = false
        if (res.response == true) {
          router.push({ name: 'Transfer-in' })
        }
      })
    }

    const checkStatusComfirm = () => {
      if (statusClick.value == 1) {
        comfirmTransfers()
      } else if (statusClick.value == 2) {
        confirmCancle()
      }
    }

    const confirmCancle = () => {
      const body = {
        transfer_id_pri: transfer_id.value,
        transfer_status_id: '3',
        transfer_comment: transfer_comment.value,
      }
      transferInStatusUpdate(body).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? 'cancelListSuccess' : 'unsuccessful',
          color: res.response ? 'success' : 'error',
        })
        if (res.response == true) {
          router.push({ name: 'Transfer-in' })
        }
        loadingProcess.value = false
      })
    }

    const getDrugTranferIn = () => {
      transferInDrugGet(transfer_id.value).then(res => {
        drugTransferInList.value = res.data
        getDrugInShop()
      })
    }
    const getDrugInShop = () => {
      statusMapData.value = false
      const body = {
        searchtext: '',
        druggroup_id: '',
        drug_category_id: '',
        drug_status_id: '1',
        active_page: '1',
        per_page: 0,
      }
      drugList(body).then(async res => {
        drugInShopList.value = res.data
        const status = await new Promise(resolve => {
          let o = 0
          while (o < drugTransferInList.value.length) {
            let i = 0
            while (i < res.data.length) {
              if (
                res.data[i].drug_id
              == drugTransferInList.value[o].transferorder_drug_id
              ) {
                fillerDrugInShopList.value[o] = res.data[i]

                // resolve(true);
                break
              } else {
                fillerDrugInShopList.value[o] = {}
              }

              i++
            }
            if (o == drugTransferInList.value.length - 1) {
              resolve(true)
            }
            o++
          }
        })
        statusMapData.value = !!status
        checkNull()
      })
    }

    return {
      loadingProcess,
      loading,
      statusCheck,
      statusClick,
      statusOpenConfirmCreate,
      statusMapData,
      statusUpdateNavbar,
      transfer_comment,
      transfer_id,
      transferData,
      userData,
      dataUserRole,
      profileData,
      drugInShopList,
      fillerDrugInShopList,
      drugTransferInList,
      comment,
      dialogCancel,
      isUserComfirm,
      checkStatusComfirm,
      checkNull,
      comfirmTransfers,
      getDrugInShop,
      getDrugTranferIn,
      getProfile,
      getTransferIn,
      required,

      icons: {
        mdiArrowLeftBold,
      },
    }
  },

}
</script>
