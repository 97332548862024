import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const transferInList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'transferin/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get trasfer in list err : ', err)

      return {}
    })

  return response
}

const transferInGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'transferin',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get trasfer in err : ', err)

      return {}
    })

  return response
}

const transferInDrugGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'transferin/drug',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get trasfer in drug err : ', err)

      return {}
    })

  return response
}

const transferInStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'transferin/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update statys trasfer in err : ', err)

      return {}
    })

  return response
}
export default {
  transferInList, transferInGet, transferInDrugGet, transferInStatusUpdate,
}
